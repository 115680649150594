import api from '@/plugins/axios'

const base = 'v1/investidor/suitability/'

export default {
  getQuestions () {
    return api.get(`${base}questoes`)
  },
  sendAnswers (data) {
    return api.post(`${base}gravar`, data)
  },
  statusCadastro () {
    return api.get(`v1/investidor/status-cadastro`)
  }
}
