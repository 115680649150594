<template>
  <div class="modal">
    <div class="backdrop" @click="$emit('closeModal')"/>
    <div class="container flex justify-center items-center">
      <div class="content">
        <p class="close" @click="$emit('closeModal')">
          <i class="fas fa-times"></i>
        </p>
        <div class="text-center mt-5">
          <ul class="subtitle">
            <p class="font-bold">Questionário de Suitability Análise de Adequação dos produtos e serviços ao Perfil do Investidor versão 20/10/2017.</p>
            <li>Procurando adequar a oferta de produtos de investimento ao perfil de cada aplicador, a TD Plataforma Ltda. (“Tutu”) elaborou o presente questionário, em cumprimento à legislação vigente.</li>
            <li>A Tutu é uma empresa com amplos conhecimentos de tecnologia e engenharia de software, especializada no mercado de crédito, possuindo expertise no desenvolvimento e gestão operacional de produtos financeiros e similares, incluindo o gerenciamento de fluxo operacional para concessão de crédito e a análise de risco de crédito voltado para pessoas físicas jurídicas.</li>
            <li>Por meio de sua plataforma digital (“Plataforma Digital da Tutu”), a Tutu presta serviços que permite que investidores (“Investidores”) e tomadores de empréstimos (“Tomadores” e, em conjunto com os Investidores, “Usuários”) entrem em contato, de modo que os primeiros possam atender a demanda de crédito dos segundos por meio da Plataforma Digital da Tutu.</li>
            <li>A Tutu não realiza as operações de empréstimo e/ou investimento diretamente, atuando como procuradora de seus Usuários (i) na contratação de empréstimos perante a instituição financeira parceira, representados por Cédulas de Crédito Bancário – CCBs emitidas por tais Tomadores em favor da instituição financeira parceira, nos termos da Lei nº 10.931, de 2 de agosto de 2004, conforme alterada; (ii) nos endossos das CCBs, inadimplidas ou não, aos Investidores, sendo que a instituição financeira parceira se compromete a ceder a totalidade dos créditos destas CCBs e/ou endossá-las em favor dos Investidores; e/ou (iii) como agente de cobrança, judicial ou extrajudicial, relativo ao crédito de titularidade da instituição financeira parceira e/ou dos Investidores.</li>
            <li>O objetivo deste questionário é auxiliar na definição da capacidade de investimento no conteúdo e no material disponibilizado na Plataforma Digital da Tutu, por meio da classificação da exposição e tolerância ao risco frente às metas de investimento. Ressalta-se que não existem respostas certas ou erradas. O Investidor deverá responder este questionário escolhendo as operações que mais se aproximam do seu perfil, o qual poderá ser classificado como:</li>
            <ul class="subtitle">
              <li><span class="text-primary font-bold">INVESTIDOR AGRESSIVO (A)</span>  É aquele investidor que busca a rentabilidade que a alocação em ativos de mais longo prazo pode oferecer, por meio de uma estratégia mais agressiva em operações no mercado de ações, de alavancagem em produtos derivativos e de crédito. Entende sobre o alto risco dos referidos mercados e tem disposição para suportar as oscilações no valor da cota e eventuais perdas de patrimônio na busca de melhores resultados</li>
              <li><span class="text-primary font-bold">INVESTIDOR MODERADO (M)</span>  É aquele investidor que mantém parte de seus investimentos em produtos mais seguros, tais como títulos públicos e ativos de renda fixa de emissor privado. Entretanto, também quer buscar um retorno adicional e acima da média por meio de investimentos em produtos com maior risco de mercado e de crédito e de menor liquidez.</li>
              <li><span class="text-primary font-bold">INVESTIDOR CONSERVADOR (C)</span>  É aquele investidor que tem a segurança como ponto decisivo para seus investimentos e que não está familiarizado com a volatilidade dos mercados. Também não tem tolerância a riscos de crédito e de eventuais dificuldades de liquidez.</li>
            </ul>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'suitability-info-modal'
}
</script>

<style lang="sass" scoped>

.modal
  @apply fixed flex items-center justify-center w-screen h-screen top-0 left-0 z-50

.backdrop
  @apply fixed z-40 w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)

.content
  @apply relative w-full h-screen bg-white z-50 flex flex-col rounded-lg pt-4 px-2 overflow-y-auto
  @screen lg
    @apply w-10/12 px-4 h-full justify-center

.close
  @apply absolute right-0 top-0 text-secondary rounded-full p-1 m-1 cursor-pointer
  @screen lg
    @apply m-2 p-2

.title
  @apply text-secondary font-bold mb-2 text-center mt-2
  @screen lg
    @apply text-lg

.subtitle
  @apply text-xs text-justify list-disc px-3 mb-5
  @screen lg
    @apply text-sm
  li
    @apply opacity-75 my-1

</style>
