<template>
  <div class="flex flex-col">
    <div class="row text-center lg:justify-between items-center mt-4 my-4">
      <p class="title">Suitability - Adequação de Perfil de Investimento</p>
      <v-text-button class="text-secondary text-sm" @click="showInfo = true">Saiba mais</v-text-button>
    </div>
    <div class="row relative">
      <div class="bg-white border shadow rounded-lg p-4 pt-1 w-full mb-10">
        <span class="fa fa-spinner fa-spin text-2xl text-secondary text-center w-full mt-4" v-if="loadingPage"></span>
        <div class="w-full flex flex-col lg:flex-row lg:flex-wrap">
          <div class="hidden lg:block question w-1/2 pr-4" v-for="question of sortedQuestions" :key="`lg_${question.id}`">
            <p class="font-bold">{{`${question.numero} - ${question.texto}`}}</p>
            <p class="text-sm">{{question.nota}}</p>
            <div v-html="question.notaHTML" class="my-1"></div>
            <div v-for="alt of question.alternativas" :key="`lg_${alt.id}`">
              <v-radio
                class="alternative"
                :name="`lg_questao_${alt.suitabilityQuestaoId}`"
                :data-vv-as="`questão ${alt.suitabilityQuestaoId}`"
                :label="alt.texto"
                :value="alt.id"
                v-model="answers[alt.suitabilityQuestaoId]"
                v-validate="'required'"
              ></v-radio>
            </div>
            <span class="error">{{errors.first(`questao_${question.id}`)}}</span>
          </div>
          <div class="lg:hidden question w-full" v-for="question of questions" :key="question.id">
            <p class="font-bold">{{`${question.numero} - ${question.texto}`}}</p>
            <p class="text-sm">{{question.nota}}</p>
            <div v-html="question.notaHTML" class="my-1"></div>
            <div v-for="alt of question.alternativas" :key="alt.id">
              <v-radio
                class="alternative"
                :name="`questao_${alt.suitabilityQuestaoId}`"
                :data-vv-as="`questão ${alt.suitabilityQuestaoId}`"
                :label="alt.texto"
                :value="alt.id"
                v-model="answers[alt.suitabilityQuestaoId]"
                v-validate="'required'"
              ></v-radio>
            </div>
            <span class="error">{{errors.first(`questao_${question.id}`)}}</span>
          </div>
        </div>
        <v-button class="flex items-center justify-center" v-if="!loadingPage" @click="submit" :loading="loading">Enviar</v-button>
      </div>
    </div>
    <transition name="fade">
      <suitability-info-modal v-show="showInfo" @closeModal="showInfo = false"/>
    </transition>
  </div>
</template>

<script>
import VTextButton from '@/components/Buttons/TextButton'
import VButton from '@/components/Buttons/Button'
import VRadio from '@/components/Inputs/Radio'
import SuitabilityInfoModal from '@/components/Investor/Modal/SuitabilityInfoModal'

import Suitability from '@/services/Investor/Profile/Suitability'

export default {
  name: 'suitability',
  components: { VButton, VTextButton, VRadio, SuitabilityInfoModal },
  data () {
    return {
      loading: false,
      loadingPage: true,
      showInfo: false,

      questions: [],
      answers: {}
    }
  },
  computed: {
    sortedQuestions () {
      let start = 0
      let half = Math.floor(this.questions.length / 2)
      return this.questions.map((value, i, array) => i % 2 === 0 ? array[start++] : array[half++])
    }
  },
  methods: {
    async submit () {
      if (!this.loading) {
        if (await this.$validator.validateAll()) {
          this.loading = true
          const answers = Object.entries(this.answers).map(([key, value]) => ({
            questaoId: +key,
            alternativaId: value
          }))
          try {
            await Suitability.sendAnswers(answers)
            this.$store.dispatch('notification', { type: 'success', message: 'Formulário enviado com sucesso!' })
            this.$router.push('/investidor/painel')
          } catch (error) {
            this.$store.dispatch('notification', { type: 'error', message: 'Houve um erro. Revise o formulário.' })
            if (error.response && error.response.data && error.response.data.erros) {
              Object.entries(error.response.data.erros).forEach(([campo, erro]) => this.errors.add({ field: campo, msg: erro }))
            }
          } finally {
            this.loading = false
          }
        } else {
          this.$store.dispatch('notification', { type: 'error', message: 'Há algum campo inválido. Revise o formulário.' })
        }
      }
    }
  },
  async mounted () {
    try {
      // if ((await Suitability.statusCadastro()).data.data.suitabilityEstaPreenchido) return this.$router.push('/investidor/painel')
    } catch (error) {
      console.error(error)
    }
    try {
      this.questions = (await Suitability.getQuestions()).data.data
      this.questions.forEach(q => { this.answers[q.id] = null })
      this.loadingPage = false
    } catch (error) {
      console.error(error)
    }
  }
}

</script>

<style lang="sass" scoped>

.row
  @apply w-full flex flex-col
  @screen lg
    @apply flex-row

.title
  @apply font-bold text-xl text-secondary my-2 w-full
  @screen lg
    @apply text-2xl my-auto w-auto

.avatar
  @apply rounded-lg bg-white border shadow flex flex-col items-center py-4 my-2
  @screen md
    @apply h-144 ml-4 justify-between

.text
  @apply my-2

.question
  @apply my-4 text-sm

.alternative
  @apply my-1

.error
  @apply text-xs text-red-600

</style>
